.termSection {
  position: relative;
  background-color: #FFFF !important;
  padding-top: calc(var(--mantine-spacing-xl) * 2);
  padding-bottom: calc(var(--mantine-spacing-xl) * 2);
}

.section {
  padding: 40px 60px !important;
  @media (max-width: 850px) {
    padding: 40px 24px !important;
  }
}

.paddingPage {
  padding: 0px 100px !important;
  @media (max-width: 768px) {
    padding: 0px !important;
  }
}

.titleTerm {
  font-size: 26px !important;
  line-height: 34px !important;
  font-weight: bold !important;
  color: #043056 !important;

  @media (max-width: 768px) {
    font-size: 20px !important;
    line-height: 28px !important;
  }
}

.captionTerm {
  font-size: 14px !important;
  line-height: 22px !important;

  @media (max-width: 768px) {
    font-size: 13px !important;
    line-height: 24px !important;
  }
}
.programCard {
  width: 100%;
  border-radius: 15px !important;
  padding: 8px !important;
  cursor: pointer;
  transition: .3s transform cubic-bezier(.155,1.105,.295,1.12), .3s box-shadow, .3s -webkit-transform cubic-bezier(.155,1.105,.295,1.12);
  &:hover {
    transform: scale(1.02);
    box-shadow: 0 0 0 1px #f03e3e;
  }
}

.posterProgram {
  position: relative;
  min-height: 20.5rem;
  border-top-left-radius: 15px !important;
  border-top-right-radius: 15px !important;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  @media(max-width: 1400px)
  {
    min-height: 14rem;
  }
  @media(max-width: 768px) {
    min-height: 8rem;
  }
}

.infoProgram {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  margin: 0px 16px !important;
  align-items: center;
  padding: 0px 8px !important; 
}

.timeTraining {
  font-size: 9px !important;
  @media(max-width: 768px) {
    display: none !important;
  }
}

.typeProgram {
  font-size: 9px !important;
  @media(max-width: 768px) {
    font-size: 6px !important;
    height: 10px !important;
  }
}

.titleProgram {
  height: 45px !important;
  font-weight: 600 !important;
  margin-bottom: 10px !important;
  line-height: 22px !important;
  @media(max-width: 1400px) {
    font-size: 13px !important;
    height: 42px !important;
    line-height: 18px !important;
    margin-bottom: 4px !important;
  }
  @media(max-width: 768px) {
    height: 22px !important;
    font-size: 8px !important;
    text-overflow: ellipsis;
    line-height: 12px !important;
  }
}

.contentProgram {
  padding: 8px;
  @media(max-width: 768px) {
    padding: 4px !important;
  }
}

.metaLocation {
  height: 65px !important;
  @media(max-width: 1400px) {
    height: 50px !important;
  }
  @media(max-width: 768px) {
    height: 45px !important;
  }
}

.titleSchedule {
  font-size: 13px !important;
  font-weight: 500 !important;
  @media(max-width: 1400px) {
    font-size: 11px !important;
  }
  @media(max-width: 768px) {
    font-size: 8px !important;
  }
}

.contentSchedule {
  font-size: 15px !important;
  font-weight: 600 !important;
  @media(max-width: 1400px) {
    font-size: 12px !important;
  }
  @media(max-width: 768px) {
    font-size: 8px !important;
  }
}

.priceProgram {
  font-weight: 600 !important;
  @media(max-width: 768px) {
    font-size: 10px !important;
    font-weight: 500 !important;
    text-overflow: ellipsis;
  }
}
.textLineThrought {
  text-decoration: line-through !important;
  color: #fa5252 !important;
}

.blinkPrice {
  font-size: 12px !important;
  text-decoration: blink !important;
  animation-name: blinker !important;
  animation-duration: 0.6s !important;
  animation-iteration-count: infinite !important;
  animation-timing-function: ease-in-out !important;
  animation-direction: alternate !important;
  @media(max-width: 768px) {
    margin-bottom: 2px !important;
    font-size: 8px !important;
    text-overflow: ellipsis;
  }
}

.discountText {
  background-color: #FDE7E7 !important;
  font-weight: 500 !important;
  color: red !important;
  padding: 3px 6px 3px !important;
  border-radius: 5px !important;
  font-size: 12px !important;
  @media(max-width: 768px) {
    font-size: 8px !important;
    padding: 2px 4px 2px !important;
  }
}

.buttonProgram {
  @media(max-width: 768px) {
    height: 24px !important;
    font-size: 10px !important;
    font-weight: 500 !important;
    text-overflow: ellipsis;
  }
}

@keyframes blinker {
  0% {
    opacity: 1.0;
  }
  100% {
    opacity: 0.0;
  }
}
          


.validatePage {
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
}

.validateFormLayout {
  padding: 40px !important;
  width: 450px !important;

  @media (max-width: 768px) {
    padding: 20px !important;
    width: auto !important;
  }
}
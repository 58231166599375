.swiper {
  width: 100%;
  height: 100%;
}

.swiper-wrapper {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  @media (max-width: 768px) {
    justify-content: space-between !important
  }
}

.swiper-slide {
  text-align: center !important;
  font-size: 18px !important;
  background: #fff !important;

  /* Center slide text vertically */
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.swiper-slide img {
  display: block !important;
  width: 80% !important;
  height: 100% !important;
  object-fit: cover !important;
}

.swiper-slide:nth-child(2n) {
  width: 8% !important;
  @media (max-width: 768px) {
    width: 100% !important;
  }
}

.swiper-slide:nth-child(3n) {
  width: 8% !important;
  @media (max-width: 768px) {
    width: 100% !important;
  }
}
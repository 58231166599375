.link {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: rgb(73, 80, 87);
  padding: 0.625rem 0.75rem;
  border-radius: 0.25rem;
  font-weight: 700;
  font-size: 14px !important;

  &:hover {
    background-color: rgb(248, 249, 250);
    color: rgb(0, 0, 0);
  }

  &[data-active] {
    color: #E72E12;
  }
}

.linkIcon {
  color: rgb(134, 142, 150) !important;
  margin-right: 16px;

  &[data-active] {
    color: #E72E12 !important;
  }
}
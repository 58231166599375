.customSection {
  position: relative;
  background-color: #043056 !important;
  padding-top: calc(var(--mantine-spacing-xl) * 2);
  padding-bottom: calc(var(--mantine-spacing-xl) * 2);
}

.defaultSection {
  position: relative;
  background-color: #FFFF !important;
  padding-top: calc(var(--mantine-spacing-xl) * 3);
  padding-bottom: calc(var(--mantine-spacing-xl) * 3);
}

.section {
  padding: 30px 60px !important;
  @media (max-width: 850px) {
    padding: 30px 24px !important;
  }
}

.paddingPage {
  padding: 0px 30px !important;
  @media (max-width: 768px) {
    padding: 0px !important;
  }
}

.titleSection {
  color: #043056 !important;
  font-size: 28px !important;
  line-height: 34px !important;
  font-weight: bold !important;
  @media (max-width: 768px) {
    font-size: 20px !important;
    line-height: 28px !important;
  }
}

.captionSection {
  font-size: 18px !important;
  line-height: 24px !important;
  font-weight: 500 !important;
  @media (max-width: 768px) {
    font-size: 14px !important;
    line-height: 22px !important;
  }
}

.titleSectionDefault {
  color: #F3901F !important;
  font-size: 28px !important;
  line-height: 34px !important;
  font-weight: bold !important;
  @media (max-width: 768px) {
    font-size: 20px !important;
    line-height: 28px !important;
  }
}

.captionSectionWhite {
  color: #ffff !important;
  font-size: 18px !important;
  line-height: 24px !important;
  font-weight: 500 !important;
  @media (max-width: 768px) {
    font-size: 14px !important;
    line-height: 22px !important;
  }
}

.bgConsultant {
  background-color: #043056;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;

  @media (max-width: 768px) {
    display: none !important;
  }
}

.consultantText {
  min-height: 516px !important;
  @media (max-width: 768px) {
    min-height: 100% !important;
  }
}

.consultantDisplay {
  margin: 200px 30px !important;
  @media (max-width: 768px) {
    margin: 40px 30px !important;
  }
}

.consultantTitle {
  font-size: 32px !important;
  line-height: 40px !important;
  font-weight: bold !important;
  color: #FFFF;
  @media (max-width: 768px) {
    font-size: 20px !important;
    line-height: 28px !important;
  }
}
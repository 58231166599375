.headerSection {
  position: relative;
  background-color: rgb(255, 255, 255);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  padding-top: calc(var(--mantine-spacing-xl) * 3);
  padding-bottom: calc(var(--mantine-spacing-xl) * 3);
}

.section {
  padding: 0px 60px !important;
  @media (max-width: 850px) {
    padding: 0px 24px !important;
  }
}

.paddingPage {
  padding: 0px 30px !important;
  @media (max-width: 768px) {
    padding: 0px !important;
  }
}

.innerHeader {
  display: flex;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column;
  }
}

.contentHeader {
  padding-top: calc(var(--mantine-spacing-xl) * 2);
  padding-bottom: calc(var(--mantine-spacing-xl) * 2);
  margin-right: calc(var(--mantine-spacing-xl) * 3);

  @media (max-width: 768px) {
    margin-right: 0;
  }
}

.titleHeader {
  font-size: 40px !important;
  line-height: 48px !important;
  font-weight: bold !important;
  color: #043056 !important;

  @media (max-width: 768px) {
    font-size: 28px !important;
    line-height: 36px !important;
  }
}

.captionHeader {
  font-size: 20px !important;
  line-height: 28px !important;
  font-weight: 500 !important;

  @media (max-width: 768px) {
    font-size: 16px !important;
    line-height: 24px !important;
  }
}

.blogSection {
  position: relative;
  background-color: #FFFF !important;
  padding-top: calc(var(--mantine-spacing-xl) * 2);
  padding-bottom: calc(var(--mantine-spacing-xl) * 2);
}

.titleBlog {
  font-size: 30px !important;
  line-height: 38px !important;
  font-weight: bold !important;
  color: #043056 !important;

  @media (max-width: 768px) {
    font-size: 18px !important;
    line-height: 26px !important;
  }
}

.captionBlog {
  font-size: 20px !important;
  line-height: 28px !important;
  font-weight: 500 !important;

  @media (max-width: 768px) {
    font-size: 12px !important;
    line-height: 20px !important;
  }
}

.posterBlog {
  height: 32rem;
  width: 100%;
  object-fit: cover;
  border-radius: 1rem;

  @media (max-width: 768px) {
    height: 14rem;
  }
}

.titleNews {
  font-size: 18px !important;
  line-height: 26px !important;
  font-weight: bold !important;

  @media (max-width: 768px) {
    font-size: 16px !important;
    line-height: 24px !important;
  }
}

.captionNews {
  font-size: 13px !important;
  line-height: 21px !important;

  @media (max-width: 768px) {
    font-size: 11px !important;
    line-height: 21px !important;
  }
}
.checkoutSection {
  position: relative;
  background-color: #FFFF !important;
  padding-top: calc(var(--mantine-spacing-xl) * 2);
  padding-bottom: calc(var(--mantine-spacing-xl) * 2);
}

.section {
  padding: 40px 60px !important;
  @media (max-width: 850px) {
    padding: 40px 24px !important;
  }
}

.paddingPage {
  padding: 0px 30px !important;
  @media (max-width: 768px) {
    padding: 0px !important;
  }
}

.titleHeader {
  font-size: 30px !important;
  line-height: 38px !important;
  font-weight: bold !important;
  color: #043056 !important;

  @media (max-width: 768px) {
    font-size: 18px !important;
    line-height: 26px !important;
  }
}

.titleSection {
  font-size: 18px !important;
  @media (max-width: 768px) {
    font-size: 16px !important;
  }
}

.captionSection {
  font-size: 14px !important;
  @media (max-width: 768px) {
    font-size: 12px !important;
  }
}

.activeCard {
  border: 0.0625rem solid !important;
  border-color: #1c7ed6 !important;
}

.cardBorder {
  border: 0.0625rem solid !important;
  border-color: #dee2e6 !important;
}

.footerCheckout {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  background-color: #FFFF;
  max-width: 100%;
  border-top: 1px solid gainsboro;
}

.innerFooter {
  display: flex;
  justify-content: right;
  align-items: center;
  padding: 20px 60px !important;
  height: 100%;

  @media (max-width: 850px) {
    padding: 20px 24px !important;
  }
}

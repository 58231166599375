.footerSection {
  position: relative;
  background-color: #FFFF !important;
  padding-top: calc(var(--mantine-spacing-xl) * 2);
  padding-bottom: calc(var(--mantine-spacing-xl) * 2);
}

.section {
  padding: 80px 60px 22px 60px !important;
  @media (max-width: 850px) {
    padding: 40px 24px 22px 24px !important;
  }
}

.paddingPage {
  padding: 0px 30px !important;
  @media (max-width: 768px) {
    padding: 0px !important;
  }
}

.routeLink {
  cursor: pointer;

  &:hover {
    color: #E72E12;
  }
}
.headerSection {
  position: relative;
  background-color: rgb(255, 255, 255);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  padding-top: calc(var(--mantine-spacing-xl) * 3);
  padding-bottom: calc(var(--mantine-spacing-xl) * 3);
}

.section {
  padding: 0px 60px !important;
  @media (max-width: 850px) {
    padding: 0px 24px !important;
  }
}

.paddingPage {
  padding: 0px 30px !important;
  @media (max-width: 768px) {
    padding: 0px !important;
  }
}

.innerHeader {
  display: flex;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column;
  }
}

.contentHeader {
  padding-top: calc(var(--mantine-spacing-xl) * 2);
  padding-bottom: calc(var(--mantine-spacing-xl) * 2);
  margin-right: calc(var(--mantine-spacing-xl) * 3);

  @media (max-width: 768px) {
    margin-right: 0;
  }
}

.titleHeader {
  font-size: 40px !important;
  line-height: 48px !important;
  font-weight: bold !important;
  color: #043056 !important;

  @media (max-width: 768px) {
    font-size: 28px !important;
    line-height: 36px !important;
  }
}

.captionHeader {
  font-size: 20px !important;
  line-height: 28px !important;
  font-weight: 500 !important;

  @media (max-width: 768px) {
    font-size: 16px !important;
    line-height: 24px !important;
  }
}

.buttonHeader {
  background-color: #043056 !important;
  height: 45px;
}

.customSection {
  position: relative;
  background-color: #043056 !important;
  padding-top: calc(var(--mantine-spacing-xl) * 2);
  padding-bottom: calc(var(--mantine-spacing-xl) * 2);
}

.defaultSection {
  position: relative;
  background-color: #FFFF !important;
  padding-top: calc(var(--mantine-spacing-xl) * 2);
  padding-bottom: calc(var(--mantine-spacing-xl) * 2);
}

.bgGraySection {
  position: relative;
  background-color: #f7f7f7 !important;
  padding-top: calc(var(--mantine-spacing-xl) * 2);
  padding-bottom: calc(var(--mantine-spacing-xl) * 2);
}

.titleIntro {
  color: #E72E12 !important;
  font-size: 22px !important;
  line-height: 30px !important;
  font-weight: bold !important;
}

.titleSection {
  color: #043056 !important;
  font-size: 28px !important;
  line-height: 34px !important;
  font-weight: bold !important;
  @media (max-width: 768px) {
    font-size: 20px !important;
    line-height: 28px !important;
  }
}

.captionSection {
  font-size: 18px !important;
  line-height: 24px !important;
  font-weight: 500 !important;
  @media (max-width: 768px) {
    font-size: 14px !important;
    line-height: 22px !important;
  }
}

.gridProgramCard {
  padding: 30px !important;
  @media (max-width: 768px) {
    padding: 0px !important;
  }
}

.bgConsultant {
  background-color: #043056;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;

  @media (max-width: 768px) {
    display: none !important;
  }
}

.consultantText {
  min-height: 516px !important;
  @media (max-width: 768px) {
    min-height: 100% !important;
  }
}

.consultantDisplay {
  margin: 200px 30px !important;
  @media (max-width: 768px) {
    margin: 40px 30px !important;
  }
}

.consultantTitle {
  font-size: 32px !important;
  line-height: 40px !important;
  font-weight: bold !important;
  color: #FFFF;
  @media (max-width: 768px) {
    font-size: 20px !important;
    line-height: 28px !important;
  }
}

.logoPartner {
  width: 10% !important;
  aspect-ratio: 3/2 !important;
  object-fit: contain;
}


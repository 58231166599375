.root {
  position: fixed;
  height: 80px;
  background-color: #043056;
  width: 100%;
  z-index: 2;
}

.sectionHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  padding: 0px 60px;

  @media (max-width: 850px) {
    padding: 0px 24px;
  }
}

.logoSize {
  width: 125px;
}

.sectionMenu {
  display: flex;
  align-items: center;
}

.dropdown {
  position: absolute;
  top: 5rem;
  left: 0;
  right: 0;
  z-index: 0;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  border-top-width: 0;
  overflow: hidden;
  color: #3d3d3d;

  @media (min-width: 850px) {
    display: none !important;
  }
  
}

.linkHeader {
  box-sizing: border-box;
  display: flex;
  flex-flow: wrap;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: start;
  justify-content: flex-start;
  gap: 0.3125rem;

  @media (max-width: 850px) {
    display: none;
  }
}

.burger {
  @media (min-width: 850px) {
    display: none;
  }
}

.link {
  display: block;
  line-height: 1;
  padding: 0.5rem 0.40rem;
  border-radius: var(--mantine-radius-sm);
  text-decoration: none;
  color: #ffff;
  font-size: var(--mantine-font-size-sm);
  font-weight: 700;

  @media (max-width: 850px) {
    padding: 1rem;
    color: #3d3d3d;
    border-radius: 0;
  }

  &:hover {
    color: #E72E12;
  }

  &[data-active] {
    color: #E72E12;
  }
}

.publicButton {
  display: flex;
  @media (max-width: 850px) {
    display: none;
  }
}

.bgHeaderDropdown {
  min-height: 48px;
  background-color: #043056;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.menuMember {
  position: absolute;
  top: 18px;
  left: 14px;
}
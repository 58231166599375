.titleHeader {
  font-size: 24px !important;
  line-height: 32px !important;

  @media (max-width: 768px) {
    font-size: 20px !important;
    line-height: 28px !important;
  }
}

.titleSection {
  font-size: 20px;
  line-height: 28px;
}

.titleDashboard {
  font-size: 20px !important;
  line-height: 28px !important;

  @media (max-width: 768px) {
    font-size: 16px !important;
    line-height: 24px !important;
  }
}

.programCard {
  width: 100%;
  border-radius: 15px !important;
  padding: 8px !important;
  cursor: pointer;
}

.contentCard {
  padding: 8px;
  @media(max-width: 768px) {
    padding: 4px !important;
  }
}

.borderCurriculum {
  border: 1px solid #dee2e6;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 14px;
}
.authLayout {
  background-color: #ffff;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;

  @media (max-width: 850px) {
    display: none;
  }
}

.titleHeader {
  font-size: 36px !important;
  line-height: 43px !important;
  font-weight: bold !important;
  color: #043056 !important;

  @media (max-width: 850px) {
    font-size: 26px !important;
    line-height: 33px !important;
  }
}